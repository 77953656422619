:root {
  --category-content-font-size-999: 0.9rem;
  --category-content-font-size-1200: 1rem;
  --category-content-font-size-1399: 1.1rem;
  --category-content-font-size: 1.2rem;
  --category-content-font-size-1600: 1.3rem;
  --category-content-font-size-1800: 1.4rem;
  --category-content-font-size-2000: 1.5rem;
}
.store {
  margin-top: 0;
}

.store .storeRow {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.store h2 {
  text-align: center;
  padding: 13px 0;
}

.store .restaurant-hero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.9vw 3vw;
  height: auto;
  color: #f6f6f6;
}

.store .restaurant-hero .store-info {
  text-align: left;
}

.store-info {
  background-color: transparent;
  justify-content: space-between;
}

.mobile-store-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.store-name-info {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.store-name-info {
  height: 8vw;
}

.store-pic {
  height: 6vw;
  width: 6vw;
  border-radius: 50%;
  margin-right: 1vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.store-name-descript {
  display: flex;
  flex-direction: column;
  width: 60vw;
}

.store-name {
  font-size: 2.5rem;
  margin-left: 1vw;
  margin-bottom: 0;
  font-weight: inherit;
  display: flex;
  align-items: flex-end;
  flex: 2;
}

.store-description {
  display: flex;
  align-items: flex-start;
  flex: 1;
  line-height: initial;
  overflow-y: hidden;
  white-space: pre-line;
  max-height: 4vw;
  width: 100%;
}

.store-detail-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 1vw;
  width: 100%;
}

.store-address {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.store-address,
.store-phone,
.store-cuisine {
  margin-right: 1vw;
}

.store-address,
.store-phone,
.store-cuisine,
.store-service {
  text-align: start;
  display: flex;
  align-items: center;
}

.item-pic {
  margin-right: 1vw;

}

.storeWebContent {
  display: flex;
  flex-direction: row;
  margin: 0 3vw;
}

.storeMobileContent {
  display: flex;
  flex-direction: column;
  width: 100vw,
}

.categoryAndMenuWeb {
  width: 63vw;
}

.categoryAndMenuWeb .categoryAndMenuHeaderWeb {
  width: 63vw;
}

.categoryNav {
  margin: 2vw 0vw 0;
  /* min-height: 100vw; */
}

.categoryNavWeb {
  justify-content: space-between;
  align-items: center;
  height: 3vw;
  width: 75vw;
  padding: 0;
}

.categoryNavLink {
  width: fit-content;
  display: inline-block;
  padding: 0;
}

.categoryNavLink:hover {
  color: transparent;
}

.categoryAndMenuMobile {
  width: 100vw;
}

.itemList {
  background-color: transparent;
  width: 63vw;
}

.checkoutWeb {
  width: 27vw;
}

.store>.menu-summary {
  position: sticky !important;
  right: 20px;
  margin: 0;
}

.nav-link:hover {
  border-bottom-width: thin;
  border-bottom-style: solid;
}

.headroom--pinned {

  z-index: 100000 !important;
}

.headroom-wrapper {
  height: auto !important;

}

.headroom--unpinned>.menu-nav {
  margin: 3vw;
  width: 63vw;
}

.headroom--unpinned {
  transform: translate3d(0px, 0px, 0px) !important;
}

.table-info-mobile {
  height: 2rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw !important;
}

.table-info-text-mobile {
  font-size: 1.1rem;
}

.category-dropdown-mobile {
  width: 94vw !important;
  margin: 0 3vw !important;
  height: 6.3vw !important;
}

.category-dropdown-mobile>.btn,
.category-dropdown-mobile>.btn:hover {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-shadow: none;
}

.categoryBtn {
  /* background-color: transparent; */
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 0px;
  padding: 0 1vw;
  font-size: var(--category-content-font-size);
  width: 100%;
  height: 100%;
}

.categoryBtn:hover,
.categoryBtn:focus {
  /* background-color: transparent; */
  border-bottom-color: transparent;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-radius: 0;
  /* font-size: 1.2rem; */
  font-weight: bold;
  box-shadow: none;
}

#more-category-selection {
  box-shadow: none;
}

#category-selection .btn {
  width: 100%;
}

.store .order-summary .item {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.store .order-summary .item span {
  float: right;
}

.store .order-summary .item span:first-child {
  float: left;
}

.store .order-summary .checkout-button {
  padding: 10px 0;
}

.store .order-summary .radioRowFormStyle {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding-bottom: 10px;
}

.menu-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.store .item-card-list .card-deck {
  justify-content: center;
}

.store .item-card-list .card-deck .card {
  flex-direction: row;
  /* margin: 5px; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.store .item-card-list .card-deck .card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.store .item-card-list .card-deck .card .card-img-left {
  background-image: url(http://travel.home.sndimg.com/content/dam/images/travel/fullset/2015/01/14ET/food-paradise-main-show.jpg.rend.hgtvcom.616.462.jpeg);
  padding-right: 1em;
  height: auto;
  width: 20rem;
  background-size: cover;
  background-position: center;
}

.store .item-card-list .card-deck .card-price {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 200;
}

.store .item-card-list .card-block {
  padding: 3.25rem;
}

.store .store-list {
  width: 27em;
}

.store .store-list .textAccent-strong {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding: "30px 0 20px";
}

.store .checkout-button-mobile {
  position: fixed;
  width: 100vw;
  bottom: 0px;
  z-index: 10000 !important;
  background-color: white;
}

.store .restaurant-hero {
  display: flex;
  height: auto;
}

.bottom-button {
  height: 8vh;
  border-radius: unset !important;
  border-style: unset !important;
}

.item-inline {
  display: inline;
}

.hours-item,
.item-inline,
.store-description {
  font-size: 1rem;
}

.item-pic {
  font-size: 1.2rem;
}

.item-second-line {
  margin-left: 3vh;
}

.hours-section {
  display: flex;
  flex-direction: row;
}

.hours-date-section {
  display: block;
  text-align: left;
  margin-right: 1vw;
}

.hours-monday {
  display: inline;
  margin-left: 5px;
  height: 25px;
}

.hours-item {
  margin: 0;
}

.hours-time-section {
  display: block;
  text-align: left;
}

.hours-closed {
  color: #f86c6b ;
}

.item-card-list {
  display: flex;
  margin-top: 2vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category-name {
  height: 80px;
  line-height: 80px;
  margin-top: 50px;
  text-align: center;
  position: relative;
  pointer-events: none;
}

.store .menu {
  display: flex;
  justify-content: center;
}

.item-dbline {
  display: flex;
  flex-direction: column;
}

.dropdown {
  height: 100%;
}

.more-category-dropdown {
  font-size: 2vw;
  height: 100%;
  padding: 0 1vw !important;
}

.dropdowntoggle-mobile {
  font-size: 3.1vw;
}

.dropdown-item {
  display: inline-block;
  right: 0;
  border: 0;
  padding: 5px 20px;
  /* height: 3vw; */
}

.show>.dropdown {
  height: 100%;
}

.show>.dropdown-menu {
  right: 0 !important;
  left: unset !important;
  border-color: transparent;
  /* margin: 0; */
  padding: 0.5vw;
  border-radius: 8px !important;
  min-width: 25vw !important;
  width: min-content !important;
  overflow-y: scroll;
}

.dropdown-toggle {
  height: 100%;
  padding: 0 20px;
}

.menuSummary {
  position: sticky !important;
  margin: 0;
  right: 3vw;
}

.categoryDescription {
  font-size: calc(var(--category-content-font-size) * 0.9);
}

.categoryContent {
  padding: 1vw;
}

@media(min-width: 1400px) {
  .store-name {
    font-size: 3.4rem !important;
  }

  .store-name-info {
    height: 10.5vw !important;
  }

  .store-description {
    max-height: 5.25vw !important;
  }

  .store-pic {
    width: 8.5vw !important;
    height: 8.5vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1.2rem !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size) * 0.9) !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.2em !important;
  }

  .category-dropdown-item-large {
    font-size: 0.8em !important;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .show>.dropdown-menu {
    min-width: 20vw !important;
    width: min-content !important;
  }

  .show>.dropdown-item {
    width: 100% !important;
    font-size: 1.2rem !important;

  }
}

@media(min-width: 1600px) {
  .store-name {
    font-size: 3.6rem !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1.3rem !important;
  }

  .store-name-info {
    height: 10.5vw !important;
  }

  .store-description {
    max-height: 5vw !important;
  }

  .store-pic {
    width: 8.3vw !important;
    height: 8.3vw !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-1600) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-1600) * 0.9) !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.3rem !important;
  }

  .category-dropdown-item-large {
    font-size: 1em !important;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .show>.dropdown-menu {
    min-width: 20vw !important;
    width: min-content !important;
  }

  .show>.dropdown-item {
    width: 100% !important;
    font-size: 1.3rem !important;

  }
}

@media(min-width: 1800px) {
  .store-name {
    font-size: 4rem !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1.4rem !important;
  }

  .store-name-info {
    height: 10vw !important;
  }

  .store-description {
    max-height: 5vw !important;
  }

  .store-pic {
    width: 8vw !important;
    height: 8vw !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-1800) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-1800) * 0.9) !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.4rem !important;
  }

  .category-dropdown-item-large {
    font-size: 1.1em !important;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .show>.dropdown-menu {
    min-width: 15vw !important;
    width: min-content !important;
  }

  .show>.dropdown-item {
    width: 100% !important;
    font-size: 1.4rem !important;

  }
}

@media(min-width: 2000px) {
  .store-name {
    font-size: 4.5rem !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1.5rem !important;
  }

  .store-name-info {
    height: 10vw !important;
  }

  .store-description {
    max-height: 5vw !important;
  }

  .store-pic {
    width: 8vw !important;
    height: 8vw !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-2000) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-2000) * 0.9) !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.5rem !important;
  }

  .category-dropdown-item-large {
    font-size: 1.2em !important;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .show>.dropdown-menu {
    min-width: 13vw !important;
    width: min-content !important;
  }

  .show>.dropdown-item {
    width: 100% !important;
    font-size: 1.4rem !important;

  }
}

@media(max-width: 1399px) {
  .store-name-descript {
    width: 57vw !important;
  }

  .store-name {
    font-size: 3.2rem !important;
  }

  .store-name-info {
    height: 9vw !important;
  }

  .store-description {
    width: 95% !important;
    max-height: 5vw !important;
  }

  .store-pic {
    width: 8.5vw !important;
    height: 8.5vw !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-1399) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-1399) * 0.9) !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1rem !important;
  }
}

@media(max-width: 1200px) {
  .store-name-descript {
    width: 60vw !important;
  }

  .store-name {
    font-size: 3rem !important;
  }

  .store-name-info {
    height: 10vw !important;
  }

  .store-description {
    max-height: 5vw !important;
    width: 90% !important;
  }

  .store-pic {
    width: 8vw !important;
    height: 8vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.9rem !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-1200) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-1200) * 0.9) !important;
  }
}


@media(min-width: 999px) {
  .restaurant-hero {
    margin: 3vw 3vw 1.5vw;
    flex-direction: row;
  }

}

@media(max-width: 999px) {
  .restaurant-hero {
    margin: 3vw 0vw 1.5vw;
    flex-direction: column !important;
  }

  .store-name-descript {
    width: 60vw !important;
  }

  .store-name-info {
    height: 12vw !important;
  }

  .store-description {
    max-height: 6vw !important;
    width: 95% !important;
  }

  .store-pic {
    width: 10vw !important;
    height: 10vw !important;
  }

  .categoryBtn {
    font-size: var(--category-content-font-size-999) !important;
  }

  .categoryDescription {
    font-size: calc(var(--category-content-font-size-999) * 0.9) !important;
  }

  .categoryNavWeb {
    height: 4vw !important;
  }
}


@media(max-width: 900px) {
  .store-name {
    font-size: 2.7rem !important;
  }

  p {
    font-size: 0.9rem !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.8rem !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.2rem !important;
  }

  .category-dropdown-item-large {
    font-size: 0.9em !important;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .show>.dropdown-menu {
    min-width: 25vw !important;
    width: min-content !important;
    right: 0 !important;
    left: unset !important;
    margin: 0;
    /* max-height: 50vw !important; */
    overflow-y: scroll;
  }

  .show>.dropdown-item {
    width: 100% !important;
  }
  .menu-summary .checkout-button .btn  {
    padding: 0.5rem 0.75rem;
  }
  
}

@media(max-width: 800px) {
  .restaurant-hero {
    margin: 4vw 0vw 1.5vw;
  }

  .store-name-info {
    height: 14vw !important;
    font-size: 0.6rem !important;
  }

  .store-description {
    max-height: 10vw !important;
  }

  .store-name-descript {
    width: 56vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.7rem !important;
  }

  .store-pic {
    width: 11vw !important;
    height: 11vw !important;
  }

  .item-pic {
    margin-right: 0.5vw !important;
  }
}

@media (max-width: 700px) {
  .restaurant-hero {
    margin: 5.8vw 0vw 1.5vw !important;
  }

  .store-name {
    font-size: 2.5rem !important;
  }

  .store-pic {
    width: 12vw !important;
    height: 12vw !important;
  }

  .store-name-descript {
    width: 53vw !important;
  }

  .store-description {
    width: 100% !important;
    max-height: 10vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 699px) {
  .category-dropdown-mobile {
    margin: 0 4vw !important;
    width: 92vw !important;
    height: 7vw !important;
  }

  .show>.dropdown-menu {
    min-width: 40vw !important;
    width: min-content !important;
    right: 0 !important;
    left: unset !important;
    top: 0;
    margin: 0;
    padding: 0vw 10px !important;
    max-height: 50vw !important;
    overflow-y: scroll;
  }

  .item-pic {
    margin-right: 0.5vw !important;
  }

  .item-card-list {
    margin: 2vw 4vw 0 !important;
    width: 92vw !important;
  }

}

@media(max-width: 680px) {

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.6rem !important;
  }

  .store-description {
    width: 100% !important;
    max-height: 15vw !important;
  }
}

@media(max-width: 615px) {

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.5rem !important;
  }

  .store-name {
    font-size: 2.5rem !important;
  }

  .item-pic {
    margin-right: 0.3vw !important;
  }

  .store-name-info {
    height: 14vw !important;
  }

  .store-description {
    max-height: 15vw !important;
  }

  .store-pic {
    width: 12vw !important;
    height: 12vw !important;
  }
}

@media(max-width: 600px) {
  .restaurant-hero {
    margin: 5.8vw 0vw 1.5vw;
  }

  .store-name {
    font-size: 2.3rem !important;
  }

  p {
    font-size: 0.8rem !important;
  }

  .hours-item,
  .item-inline,
  .store-description {
    font-size: 0.5rem !important;
  }

  .store-description {
    max-height: 16vw !important;
  }

  .item-pic {
    margin-right: 1vw !important;
    font-size: 0.5rem !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.1rem !important;
  }

  .category-dropdown-item-large {
    font-size: 0.8em !important;
  }

  .table-info-text-mobile {
    font-size: 1rem;
  }

  .dropdown-toggle-mobile {
    font-weight: bold;
  }

  .store-name-info {
    height: 17vw !important;
  }

  .store-pic {
    width: 12vw !important;
    height: 12vw !important;
  }

  .show>.dropdown-menu {
    min-width: 50vw !important;
    width: min-content !important;
    right: 0 !important;
    left: unset !important;
    top: 0;
    margin: 0;
    padding: 0vw 10px !important;
    max-height: 50vw !important;
    overflow-y: scroll;
  }

  .show>.dropdown-item {
    width: 100% !important;
  }

  .category-dropdown-mobile {
    height: 8vw !important;
    margin: 0 4vw !important;
    width: 92vw !important;
  }

  .item-card-list {
    margin: 3vw 4vw 0 !important;
    width: 92vw !important;
  }
}

@media(max-width: 570px) {
  .restaurant-hero {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 7vw 0vw 1.5vw !important;
  }

  .store-name-info {
    height: 20vw !important;
    margin: 2vw 0 !important;
  }

  .store-name-descript {
    width: 75vw !important;
  }

  .store-description {
    width: 100% !important;
    max-height: 12vw !important;
  }

  .store-pic {
    width: 15vw !important;
    height: 15vw !important;
  }

  .hours-section {
    margin-top: 1vw;
  }

  .store-detail-info {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

@media (max-width: 500px) {
  .store-name {
    font-size: 2.2rem !important;
  }

  .category-dropdown-mobile {
    height: 9vw !important;
    margin: 0 4vw !important;
    width: 92vw !important;
  }

  .store-name-info {
    height: 22vw !important;
    margin: 3vw 0 !important;
  }

  .store-description {
    max-height: 17vw !important;
  }

  .store-pic {
    width: 17vw !important;
    height: 17vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 0.7rem !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1.1rem !important;
  }

  .category-dropdown-item-large {
    font-size: 0.8em !important;
  }
}

@media (max-width: 400px) {
  .store-name {
    font-size: 1.8rem !important;
  }

  .store-name-info {
    height: 35vw !important;
    margin: 3vw 0 !important;
  }

  .store-description {
    max-height: 28vw !important;
  }

  .store-pic {
    width: 19vw !important;
    height: 19vw !important;
  }

  .category-dropdown-mobile {
    height: 10vw !important;
    margin: 0 4vw !important;
    width: 92vw !important;
  }

  .item-card-list {
    margin: 3vw 4vw 0 !important;
    width: 92vw !important;
  }

  .category-dropdown-item,
  .dropdown-toggle-mobile {
    font-size: 1rem !important;
  }

  .category-dropdown-item-large {
    font-size: 0.8em !important;
  }
}

@media (max-width: 300px) {
  .store-name {
    font-size: 1.3rem !important;
  }

  .store-name-info {
    height: 45vw !important;
    margin: 3vw 0 !important;
  }

  .store-description {
    max-height: 40vw !important;
  }

  .store-pic {
    width: 19vw !important;
    height: 19vw !important;
  }

  .category-dropdown-item-large {
    font-size: 0.6em !important;
  }
}

/*kiosk adjustment*/
@media(max-width: 1500px) and (min-height: 1500px) {
  .itemList {
    height: 75vh;
    overflow-y: scroll;
  }

  .item-card-list {
    margin: 2vw 3vw 0 !important;
    width: 94vw !important;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .category-dropdown-mobile>.btn,
  .category-dropdown-mobile>.btn:hover,
  .dropdown-item,
  .bottom-button {
    font-size: 1.5rem;
    letter-spacing: 1px;
  }

  .show>.dropdown-menu,
  .category-dropdown-menu {
    width: 100% !important;
  }

  .dropdown-item {
    padding: 1rem 2rem;
  }

  .restaurant-hero {
    margin: 7vw 0vw 1.5vw;
    padding: 1vh 3vw !important;
  }

  .hours-item,
  .item-inline,
  .store-description,
  .item-pic {
    font-size: 1.1rem !important;
  }
}