.input-control {
  height: 50px;
}

.window-margin {
  margin-left: 20px;
  margin-right: 20px;
}

.button-width {
  width: 80px;
}


.state {
  width: 100%;
  min-height: 34px;
}

.payment-info-error{
  color: red;
}

.placeholder-title {
  font-size: 1rem ;
}

@media (min-width: 1500px) {
  .placeholder-title {
    font-size: 1.1rem !important;
  }
}