.time-display {
  font-size: 1.2rem !important;
  margin-left: 1.5vw !important;
  background-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  height: 3rem;
  color: var(--theme-cart-title);
  border-color: var(--theme-cart-title);
  font-family: var(--theme-font-bold);
}

.time-display:hover {
  border-width: 2px;
  color: var(--theme-cart-title) !important;
  border-color: var(--theme-cart-decorate-line) !important;
}

.input.time-display{
  width: 70% !important;
}

.flatpickr-wrapper{
  margin: 0.5vw;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-family: var(--theme-font-regular);
}

.flatpickr-month {
  color: var(--theme-cart-title) !important;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day {
  color: var(--theme-cart-title) !important;
  border-color: var(--theme-cart-decorate-line) !important;
}

.flatpickr-day,
.flatpickr-weekday {
  color: var(--theme-cart-subtitle) !important;
}

.flatpickr-days {
  border: 1px solid var(--theme-cart-decorate-line) !important;
}

.flatpickr-day.selected {
  background-color: var(--theme-btn-nav-hover-background) !important;
  color: var(--theme-btn-nav-hover-text) !important;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  fill: var(--theme-cart-title) !important;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-cart-subtitle) !important;
}
.flatpickr-calendar {
  box-shadow: none !important;
  margin-left: 1.5vw  !important;
  background: var(--theme-btn-nav-background) !important;
  background-color: var(--theme-btn-nav-background) !important;
  border-color: var(--theme-btn-nav-background) !important;
  color: var(--theme-btn-nav-text) !important
}
.flatpickr-hour, 
.flatpickr-minute,
.flatpickr-am-pm {
  background-color: var(--theme-btn-nav-background) !important;
  color: var(--theme-btn-nav-text) !important;
}
.flatpickr-hour:hover,
.flatpickr-minute:hover,
.flatpickr-am-pm:hover {
  background-color: var(--theme-btn-nav-hover-background) !important;
}
.flatpickr-time-separator {
  color: var(--theme-btn-nav-text) !important;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--theme-btn-nav-background) !important;
}
.arrowUp:after {
  border-bottom-color: var(--theme-btn-nav-text) !important;
}
.arrowDown:after {
  border-top-color: var(--theme-btn-nav-text) !important;
}
.flatpickr-calendar.open {
  margin-top: 0.25rem !important;
}
.flatpickr-time{
  border-top: 0 solid transparent !important;
}

.flatpickr-time .flatpickr-am-pm:focus{
  background: none !important;
}
.flatpickr-calendar .flatpickr-hour {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.flatpickr-calendar .flatpickr-am-pm {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.numInputWrapper span{
  border: none !important;
}
@media(min-width: 1300px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.3rem !important;
  }
}
@media(min-width: 1500px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.4rem !important;
  }
}
@media(min-width: 1800px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.5rem !important;
  }
}
@media(max-width: 1299px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.2rem !important;
  }
}
@media (max-width: 1100px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.1rem !important;
  }
}
@media (max-width: 900px) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1rem !important;
  }
}
@media ( max-width: 700px ) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1.2rem !important;
  }
  
  .input.time-display{
    width: 75% !important;
  }
}
@media ( max-width: 500px ) {
  .time-display,
  .flatpickr-hour,
  .flatpickr-minute,
  .flatpickr-am-pm,
  .flatpickr-time-separator {
    font-size: 1rem !important;
  }

  .input.time-display{
    width: 100% !important;
    height: 2.8rem;
    margin: 0.25rem 0;
    padding: 0.5rem 0.8rem;
  }

  .cart-list {
    margin: 0 9.7vw 1rem !important;
  }
}
.form-control[readonly]{
  /* background-color: #ffffff !important; */
  /* color: #263238 !important; */
  width: 50%;
}

.d-flex.justify-content-between.scheduler.form-group {
  flex-flow: column;
}

.schedule-later {
  margin-left: 0px !important;
}