.store-name {
  font-weight: 600;
  letter-spacing: 1.5px;
}

.text-danger {
  margin: 0 0 1vw !important;
}

.store-name:hover {
  text-decoration: none;
  cursor: pointer;
}

.store-info-title {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.change-address-btn {
  padding: 0.5vw 0.6vw !important;
  margin: 0.5vw 0 !important;
  border-style: solid;
  border-width: 1px;
}

.cart-contents {
  margin: 1vw 4vw 0px;
  padding: 0 !important;
}

.cart-section {
  padding: 0;
  width: 100%;
}

.cart-title {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: fit-content;
  margin-bottom: 0.7vw;
  padding-bottom: 0.2vw;
  font-size: 1.7rem !important;
}

.cart-title-section {
  padding: 0;
  margin: 0 !important;
}

.cart-title-shoppingcart {
  /* margin-bottom: 0.7vw; */
  font-size: 1.35vw !important;
}

.cart-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.7vw;
  padding: 0;
}

.cart-list-title {
  font-size: 1.7rem;
  margin-bottom: 0px;
  letter-spacing: 1.2px;
}

.cart-info {
  margin: 3.5vw 0px;
  display: flex;
  flex-direction: row;
}

.cart-info .btn {
  padding: 0;
}

.cart-info .store-pic {
  width: 5.3vw;
  height: 5.3vw;
  margin-right: 1vw;
}

.cart-info .store-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.store-info .store-name {
  display: flex;
  flex-direction: row;
  margin: 0px;
  align-items: center;
}

.cart-info .textAccent-strong,
.textAccent-strong,
.login-text,
.text-first,
.store-name .store-icon {
  font-size: 1.5rem !important;
}

.text-first {
  margin: 2vw 0 !important;
}

.cart-info .textAccent-strong,
.textAccent-strong {
  font-weight: bold;
}

.store-name .store-icon {
  margin-left: 1vw;
}

.login-text {
  font-weight: bold;
  font-size: 1.3rem !important;
}

.login-text:hover,
.login-text:after {
  text-decoration: underline;
}

.store-info .text-secondary,
.store-info .text-danger,
.change-address-btn,
.text-danger,
.text-secondary {
  font-size: 1.2rem !important;
}

.cart-caption-circle {
  border-radius: 50%;
  width: 2.4vw;
  height: 2.4vw;
  align-items: center;
  display: flex;
  margin-left: 1.2vw;
  justify-content: center;
}

.cart-caption-circle .caption {
  font-size: 1.2rem;
}

.cart-reset-button {
  display: none;
}

.cart-list-contents {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.cart-list-contents .cart-items {
  height: 65vh;
  overflow-y: scroll;
}

@media(min-width: 1300px) {

  .cart-list-title,
  .cart-title {
    font-size: 1.5rem !important;
  }

  .cart-list {
    margin-bottom: 1vw !important;
  }

  .cart-info {
    margin: 1.5rem 0 2.5rem !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon .store-info .text-danger,
  .text-danger {
    font-size: 1.3rem !important;
  }

  .cart-info .store-pic {
    width: 6.3vw !important;
    height: 6.3vw !important;
    margin-right: 1vw;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 1.1rem !important;
  }

  .scheduler-warning {
    font-size: 1.1rem !important;
    color: #f86c6b !important;
  }
}

@media(min-width: 1500px) {

  .cart-list-title,
  .cart-title {
    font-size: 1.6rem !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon .store-info .text-danger,
  .text-danger {
    font-size: 1.5rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 1.2rem !important;
  }

  .scheduler-warning {
    font-size: 1.3rem !important;
    color: #f86c6b !important;
  }
}

@media(min-width: 1800px) {

  .cart-list-title,
  .cart-title {
    font-size: 1.7rem !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon .store-info .text-danger,
  .text-danger {
    font-size: 1.8rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 1.4rem !important;
  }
}

@media(max-width: 1299px) {

  .cart-list-title,
  .cart-title {
    font-size: 1.4rem !important;
  }

  .cart-list {
    margin-bottom: 1vw !important;
  }

  .cart-info {
    margin: 1.5rem 0 !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon {
    font-size: 1.2rem !important;
  }

  .cart-info .store-pic {
    width: 6.5vw !important;
    height: 6.5vw !important;
    margin-right: 1vw;
  }

  .store-info .text-secondary,
  .store-info .text-danger,
  .change-address-btn,
  .text-danger,
  .text-secondary {
    font-size: 0.9rem !important;
  }

  .scheduler-warning {
    font-size: 1rem !important;
    color: #f86c6b !important;
  }
}

@media (max-width: 1100px) {
  .cart-info .store-pic {
    width: 7.5vw !important;
    height: 7.5vw !important;
  }

  .cart-info {
    margin: 2rem 0 !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon {
    font-size: 1.2rem !important;
  }

  .store-info .text-danger,
  .text-danger {
    font-size: 1rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 0.8rem !important;
  }

  .change-address-btn {
    padding: 0.8vw 1.5vw !important;
  }

  .cart-caption-circle {
    width: 2.8vw !important;
    height: 2.8vw !important;
  }

  .scheduler-warning {
    font-size: 1rem !important;
    color: #f86c6b !important;
  }
}

@media (max-width: 900px) {
  .cart-info .store-pic {
    width: 9.3vw !important;
    height: 9.3vw !important;
  }

  .cart-info {
    margin: 6vw 0 !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon {
    font-size: 1.1rem !important;
  }

  .store-info .text-danger,
  .text-danger {
    font-size: 0.9rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 0.7rem !important;
  }

  .change-address-btn {
    padding: 1.2vw 2.2vw !important;
  }

  .cart-caption-circle {
    width: 3.3vw !important;
    height: 3.3vw !important;
  }

  .scheduler-warning {
    font-size: 0.9rem !important;
    color: #f86c6b !important;
  }
}

@media (max-width: 800px) {
  .cart-caption-circle {
    width: 3.5vw !important;
    height: 3.5vw !important;
  }
}

@media (max-width: 700px) {
  .cart-contents {
    margin: 4vw 4vw 0px !important;
    padding-bottom: 6vh !important;
  }

  .cart-list-contents {
    flex-direction: column !important;
  }

  .cart-title {
    font-size: 1.2rem !important;
  }

  .cart-info {
    margin: 4vw 0 7vw !important;
  }
  .cart-info .store-name {
    letter-spacing: 1px;
  }
  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon {
    font-size: 1.2rem !important;
  }

  .store-info .text-danger,
  .text-danger {
    font-size: 1.1rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 0.9rem !important;
  }

  .cart-list-title {
    font-size: 1.2rem !important;
  }

  .cart-caption-circle .caption {
    font-size: 1rem !important;
  }

  .cart-info .store-pic {
    width: 13vw !important;
    height: 13vw !important;
    margin-right: 2vw;
  }

  .store-name .store-icon {
    font-size: 0 !important;
    margin-left: 3vw !important;
  }

  .change-address-btn {
    padding: 1vw 2vw !important;
  }

  .cart-title-section {
    margin: 0 2rem !important;
  }

  .cart-list {
    margin: 0 2rem 0.5rem !important;
  }

  .text-first {
    margin: 0vw 2rem 0.5rem !important;
  }

  .cart-caption-circle {
    width: 5.85vw !important;
    height: 5.85vw !important;
    margin-left: 3vw;
  }
}

@media(max-width:600px) {
  .textAccent-strong {
    white-space: nowrap !important;
  }

  .scheduler-warning {
    font-size: 0.9rem !important;
    color: #f86c6b !important;
  }
}

@media (max-width: 500px) {
  .cart-title-section {
    margin: 0 1rem 0.5rem !important;
  }

  .cart-list {
    margin: 0 1rem 0.5rem !important;
  }

  .text-first {
    margin: 0 1rem 0.5rem !important;
  }

  .cart-title {
    font-size: 1rem !important;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .login-text,
  .text-first,
  .store-name .store-icon {
    font-size: 1rem !important;
  }

  .store-info .text-danger,
  .text-danger {
    font-size: 0.9rem !important;
  }

  .store-info .text-secondary,
  .text-secondary,
  .change-address-btn {
    font-size: 0.8rem !important;
  }

  .cart-list-title {
    font-size: 1rem !important;
  }

  .cart-caption-circle .caption {
    font-size: 0.9rem !important;
  }

  .cart-info .store-pic {
    width: 4rem !important;
    height: 4rem !important;
    margin-right: 2vw;
  }

  .store-name .store-icon {
    font-size: 0 !important;
  }

  .cart-caption-circle {
    width: 5.85vw !important;
    height: 5.85vw !important;
    margin-left: 3vw;
  }

  .scheduler-warning {
    font-size: 0.8rem !important;
    color: #f86c6b !important;
    white-space: pre-wrap;
  }
}

.form-control[readonly] {
  /* background-color: #ffffff !important; */
  /* color: #263238 !important; */
  width: 50%;
}

.d-flex.justify-content-between.scheduler.form-group {
  flex-flow: column;
}

.schedule-later {
  margin-left: 0px !important;
}

@media(max-width: 700px) {
  .cart-list-contents .cart-items {
    height: auto;
    margin-bottom: 1vh;
  }
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height:1500px) {
  .cart-title,
  .store-info .text-secondary,
  .cart-info .store-pic {
    display: none;
  }

  .cart-list {
    margin-bottom: 1vh !important;
  }

  .cart-list-contents {
    flex-direction: column !important;
    padding-bottom: 7vh !important;
  }

  .cart-item-card {
    width: 100% !important;
  }

  .cart-list-contents .cart-items {
    height: auto;
    margin-bottom: 1vh;
  }

  .cart-info .textAccent-strong,
  .textAccent-strong,
  .store-name .store-icon {
    font-size: 2.5rem !important;
  }

  .cart-info {
    margin: 1.5rem 0 !important;
  }

  .cart-list-title,
  .cart-title,
  .cart-caption-circle .caption {
    font-size: 1.6rem !important;
  }

  .cart-caption-circle {
    width: 4vw !important;
    height: 4vw !important;
  }

  .col-md-6 {
    width: 100%;
  }

  .cart-reset-button {
    font-size: 1.4rem !important;
    letter-spacing: 0.8px;
    display: block;
    margin-left: 1.2vw;
  }
}