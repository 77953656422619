:root {
  --item-card-title-font-size-1600: 1.2rem;
  --item-card-description-font-size-1600: 0.9rem;
  --item-card-title-font-size-1400: 1.1rem;
  --item-card-description-font-size-1400: 0.8rem;
  --item-card-title-font-size-1399: 0.95rem;
  --item-card-description-font-size-1399: 0.75rem;
  --item-card-title-font-size-1200: 0.85rem;
  --item-card-description-font-size-1200: 0.7rem;
  --item-card-title-font-size-1000: 0.75rem;
  --item-card-description-font-size-1000: 0.7rem;
}
.item-card {
  display: flex;
  flex-direction: column;
  min-height: 8vw;
  border-radius: 6px;
  margin: 0 0 1vw; 
  box-shadow: 0px 3px 6px #00000029;
  /* height: 9vw; */
  width: 100%;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

.item-card:hover {
  box-shadow: 0px 3px 6px #00000029;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22); */
  cursor: pointer;
}

.item-card .item {
  display: flex;
  height: 100%;
}

.item-card .item .info {
  align-content: space-between;
  padding: 10px 15px;
}

.item-card .item .info .name, 
.item-card .item .info .price {
  font-size: 1.5rem ;
  /* font-family: 'PetitaBold', sans-serif; */
}

.item-card .img-uploader {
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.item-card .item .img {
  height: 100%; 
  width: auto;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.item-card .item .defaultImg{
  /* height: 100%; 
  width: auto; */
  font-size: 4.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 8vw;
  min-width: 8vw;
}
.item-card .item .icon{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.namePrice{
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: center;
  font-weight: bold;
}

.name {
  flex: 5;
}
.price {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.dividing-line {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 0 0.5vw;
}
.itemDescription {
  flex: 3;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.description {
  font-size: 1.1rem;
}
.namePrice, .itemDescription {
  margin: 0 0.5vw 0 1vw;
}

@media (min-width: 1400px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: var(--item-card-title-font-size-1400) !important;
  }
  .item-card .item .info .description {
    font-size: var(--item-card-description-font-size-1400) !important;
  }
  .item-card .item .defaultImg {
    font-size: 3.7rem !important;
  }
}
@media (min-width: 1600px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: var(--item-card-title-font-size-1600) !important;
  }
  .item-card .item .info .description {
    font-size: var(--item-card-description-font-size-1600) !important;
  }
  .item-card .item .defaultImg {
    font-size: 4rem !important;
  }
}

@media (min-width: 1800px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: 1.35rem !important;
  }
  .item-card .item .info .description {
    font-size: 1.05rem !important;
  }
  .item-card .item .defaultImg {
    font-size: 4.5rem !important;
  }
}
@media (min-width: 2000px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: 1.6rem !important;
  }
  .item-card .item .info .description {
    font-size: 1.3rem !important;
  }
  .item-card .item .defaultImg {
    font-size: 5rem !important;
  }
}
@media (max-width: 1399px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: var(--item-card-title-font-size-1399) !important;
  }
  .item-card .item .info .description {
    font-size: var(--item-card-description-font-size-1399) !important;
  }
  .item-card .item .defaultImg {
    font-size: 3.6rem !important;
  }
}
@media (max-width: 1200px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: var(--item-card-title-font-size-1200) !important;
  }
  .item-card .item .info .description {
    font-size: var(--item-card-description-font-size-1200) !important;
  }
  .item-card .item .defaultImg {
    font-size: 3rem !important;
  }
}
@media (max-width: 1000px) {
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: var(--item-card-title-font-size-1000) !important;
  }
  .item-card .item .info .description {
    font-size: var(--item-card-description-font-size-1000) !important;
  }
  .item-card .item .defaultImg {
    font-size: 2.8rem !important;
  }
}
@media(max-width:900px) {
  .item-card .item .defaultImg {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 900px) {
  .item-card {
    width: 49%;
  }
}
@media (min-width: 800px) {
  .card-parent {
    min-width: 30%;
    max-width: 30%;
  }
  .item-card {
    height: 9.7vw;
  }
  .item-card .item .info {
    min-width: 9.7vw;
  }
  .item-card .item .img {
    height: 9.7vw !important;
    width: 9.7vw !important;
  }
  .item-card .item .defaultImg {
    height: 9.7vw !important;
    width: 9.7vw !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

@media (max-width: 800px) {
  .item-card {
    height: 10vw; 
  }
  .item-card .item .img {
    height: 10vw !important;
    width: 10vw !important;
  }
  .item-card .item .defaultImg {
    height: 10vw !important;
    width: 12.2vw !important;
    font-size: 2.4rem !important;
  }
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: 0.9rem !important;
  }
  .item-card .item .info .description {
    font-size: 0.7rem !important;
  }
} 
@media (max-width: 699px) {
  .item-card {
    height: 18vw; 
  }
  .item-card .item .img {
    height: 18vw !important;
    width: 18vw !important;
  }
  .item-card .item .defaultImg {
    height: 18vw !important;
    width: 22.5vw !important;
    font-size: 3.5rem !important;
  }
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: 1.2rem !important;
  } 
  .item-card .item .info .description {
    font-size: 0.9rem !important;
  }
}
@media (max-width: 600px) {
  .item-card {
    height: 20vw; 
    margin-bottom: 4vw !important;
  }
  .item-card .item .img {
    height: 20vw !important;
    width: 20vw !important;
  }
  .item-card .item .defaultImg {
    height: 20vw !important;
    width: 26vw !important;
    font-size: 3rem !important;
  }
  .item-card .item .info .name, 
  .item-card .item .info .price {
    font-size: 1rem !important;
  }
  .item-card .item .info .description {
    font-size: 0.8rem !important;
  }
} 
@media (max-width: 500px) {
  .item-card {
    height: 24vw;
  }
  .item-card .item .img {
    height: 24vw !important;
    width: 24vw !important;
  }
  .item-card .item .defaultImg {
    height: 24vw !important;
    width: 33vw !important;
    font-size: 2.9rem !important;
  }
  .item-card .item .info .name, .item-card .item .info .price {
    font-size: 0.9rem !important;
  }
  .item-card .item .info .description {
    font-size: 0.7rem !important;
  }

  .item-card .item .img {
    height: 100%;
    width: auto;
  }

  .item-card .img-uploader {
    display: none;
  }
}
@media (max-width: 400px) {
  .item-card {
    height: 25vw;
  }
  .item-card .item .img {
    height: 25vw !important;
    width:  25vw !important;
  }
  .item-card .item .defaultImg {
    height: 25vw !important;
    width: 36vw !important;
    font-size: 2.8rem !important;
  }
  .item-card .item .info .name, .item-card .item .info .price {
    font-size: 0.8rem !important;
  }
  .item-card .item .info .description {
    font-size: 0.6rem !important;
  }


  .item-card .img-uploader {
    display: none;
  }
}
@media(max-width: 300px) {
  .item-card .item .defaultImg {
    font-size: 2.2rem !important;
  }
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height:1500px) {
  .item-card .item .info .name, .item-card .item .info .price {
    font-size: 1.3rem !important;
  }
  .item-card,
  .item-card .item .defaultImg,
  .item-card .item .img {
    height: 46vw !important;
  }

  .item-card .item .kioskImg {
    width: 46vw !important;
    height: 46vw !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-self: center;
  }

  .item-card .item .img,
  .item-card .item .defaultImg {
    width: 46vw !important;
    align-self: center;
  }

  .item-card .item .info .description {
    font-size: 1.2rem !important;
  }

  .item-card {
    flex-basis: 46vw;
  }

  .item-card .item {
    flex-direction: column;
  }

  .dividing-line, .itemDescription {
    display: none;
  }

  .item-card .item .info {
    height: 12vw;
  }
}