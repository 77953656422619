.order-summary {
  background-color: white;
  border-radius: 10px;
  border-color: transparent;
  box-shadow: 0px 3px 6px #00000029;
  width: 27vw;
  min-height: 21vw;
  padding-bottom: 2vw;
}

.order-summary .delivery-type,
.order-summary .table-info {
  padding: 2vw 2vw 0;
}

.order-summary .text-danger {
  margin-bottom: 0;
  font-size: 1.1rem;
}

.order-summary .form-check-inline {
  display: flex !important;
  align-items: center !important;
}

.order-summary .form-check-inline+.form-check-inline {
  margin-left: 0;
}

.order-summary .radio-option {
  min-height: 3vw;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 0 2vw; */
  flex-direction: row;
}

.order-summary .radio-option .form-check-label {
  margin-top: 0.3rem;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 1rem !important;
  font-weight: bold;
}

.order-summary .cart-item-card {
  margin: 0;
}

.order-summary .form-control:focus {
  border-top-color: transparent !important;
  border-left-color: transparent !important;

  border-right-color: transparent !important;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.order-summary .menu-items-list {
  margin: 1.8vw 2vw 0;
  /* background-color: yellow; */
  max-height: 10vw;
  overflow-y: scroll;
  padding: 0 1vw 0 0;
}

.order-summary .fee {
  margin: 2vw 2vw !important;
  font-size: 1.3rem;
}

.order-summary .suboption-fee {
  font-size: 1.2rem !important;
  margin: 0 !important;
}

.order-summary .mt-3 {
  margin: 0 !important;
}

.order-summary .checkout-button-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-summary .pay-btn {
  border-radius: 10px;
  font-size: 1.2rem;
}

.order-summary .pay-btn {
  /* width: 15vw !important; */
  min-width: fit-content;
  border-radius: 10px;
}

.order-summary .tip-section {
  align-items: center;
  height: 3.75vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.order-summary .table-info {
  height: 3vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border: none;
}

.order-summary .table-info-text {
  font-size: 1.2rem;
  letter-spacing: 1px;
}

@media (min-width: 1300px) {

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1.2rem !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label {
    font-size: 1.4rem !important;
  }
}

@media (min-width: 1500px) {

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1.3rem !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.5em !important;
  }
}

@media (min-width: 1800px) {

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1.4rem !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.6rem !important;
  }
}

@media (max-width: 1299px) {
  .form-check-inline+.form-check-inline:last-child {
    margin-left: 0;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1rem !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.2rem !important;
  }

}

@media(max-width: 1100px) {
  .order-summary .table-info {
    padding: 3vw 2vw 0 !important;
  }
}

@media(max-width:999px) {
  .order-summary .radio-option {
    flex-wrap: wrap;
    min-height: 3vw !important;
    height: auto !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.1rem !important;
  }
}

@media(max-width: 900px) {
  .order-summary .tip-section {
    height: 5vw !important;
  }

  .order-summary .tip-btn {
    margin-right: 0.5vw !important;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 0.8rem !important;
  }

  .order-summary .text-danger {
    padding: 0.5vw 1vw 0;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 0.9rem !important;
  }

  .order-summary .delivery-type {
    padding: 2vw 1vw 0 !important;
  }
}

@media(max-width: 700px) {
  .order-summary {
    width: 100%;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1rem !important;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.2rem !important;
  }

  .order-summary .tip-section {
    height: 8vw !important;
  }

  .order-summary .tip-btn {
    margin-right: 5vw !important;
    width: 1.6vw !important;
  }

  .order-summary .fee {
    margin: 2vw 6vw !important;
  }

  .order-summary .delivery-type {
    padding: 4vw 5vw 0 !important;
  }

  .order-summary .table-info {
    padding: 5vw 6vw 2vw !important;
  }

  .order-summary .cart-list-section .text-first {
    margin-left: 9.7vw !important;
  }
}

@media(max-width: 600px) {

  .order-summary .radio-option .form-check-label,
  .order-summary .total-fee,
  .order-summary .table-info-text {
    font-size: 1.2rem !important;
  }

  .order-summary {
    width: 100% !important;
  }

  .order-summary .tip-section {
    height: 9vw !important;
  }

  .order-summary .tip-btn {
    margin-right: 4vw !important;
    width: 1.6vw !important;
  }

  .order-summary .fee {
    margin: 2vw 6vw !important;
  }

  .order-summary .delivery-type {
    padding: 5vw 5vw 0 !important;
  }

  .order-summary .table-info {
    padding: 5vw 6vw 2vw !important;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1.1rem !important;
  }
}

@media(max-width: 500px) {
  .order-summary .tip-section {
    height: 10vw !important;
  }

  .order-summary .delivery-type {
    padding: 7vw 5vw 0 !important;
  }

  .order-summary .table-info {
    padding: 6vw 6vw 2vw !important;
  }

  .order-summary .radio-option .form-check-label,
  .order-summary .total-fee,
  .order-summary .table-info-text {
    font-size: 1rem !important;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 0.9rem !important;
  }

  .order-summary .tip-btn {
    margin-right: 3.5vw !important;
  }
}

@media(max-width: 400px) {
  .order-summary .tip-section {
    height: 12vw !important;
  }

  .order-summary .delivery-type {
    padding: 7vw 5vw 0 !important;
  }

  .order-summary .table-info {
    padding: 7vw 6vw 3vw !important;
  }
}

.tip-btn {
  flex: 1;
  /* width: 4.6vw; */
  height: 100%;
  padding: unset;
  margin-right: 1vw;
  font-size: 1rem;
}

.tip-preview {
  flex: 1;
  /* width: 4.6vw; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0;
  font-size: 1.2rem;
}

.tip-preview-num {
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  padding: 0 0 0 0.5vw;
  height: 60%;
  text-align: start;
  border-color: transparent;
}

/*kiosk adjustment*/
@media (max-width: 1500px) and (min-height: 1500px) {
  .order-summary {
    width: 100%;
    height: auto;
    padding-bottom: 3vh;
  }

  .order-summary .total-fee,
  .order-summary .radio-option .form-check-label,
  .order-summary .table-info-text {
    font-size: 1.6rem !important;
  }

  .order-summary .fee,
  .order-summary .pay-btn,
  .order-summary .tip-btn,
  .order-summary .suboption-fee,
  .order-summary .tip-preview,
  .order-summary .tip-preview-num,
  .order-summary .text-danger {
    font-size: 1.4rem !important;
    letter-spacing: 0.8px;
  }

  .order-summary .delivery-type,
  .order-summary .table-info {
    padding: 3vh 4vw 0 !important;
  }

  .order-summary .fee {
    margin: 2vw 4vw !important;
  }

  .order-summary .tip-section {
    height: 5vh;
  }
  .tip-btn {
    margin-right: 3vw !important;
  }

  .order-summary .tip-preview-num {
    text-align: right;
  }
}