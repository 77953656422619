.modal-header {
  border-color: transparent;
}
.modal-content {
  border-radius: 10px;
  border-color: transparent !important;
  background-color: transparent !important;
}
.modal-whole {
  border-radius: 10px;
  padding: 1.5rem;
}
.modal-content  .dividing-line {
  /* width: 90% !important; */
  align-self: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.warning {
  /* color: red; */
  font-size: 0.8rem;
}
.item-title, .item-price {
  text-align: center;
  font-size: 1.7rem ;
  margin-bottom: 0.5vw;
}
.item-message-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.item-message {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  height: 7rem;
  margin: 10px;
  padding: 0.5rem 1rem;
  overflow-y: scroll;
  resize: none;
  /* width: 100%; */
}
.item-message-limit {
  margin: 0 10px;
  text-align: end;
}
.modal-header .item-description{
  font-size: 1rem;
  margin: 0.5rem 2rem !important;
}
.modal-dialog {
  margin-top: 10% !important;
}
.modal-dialog .modal-header span {
  font-size: 1.8rem;
}
.modal-dialog .modal-footer {
  border: none;
}
.modal-dialog .form-check-label {
  padding-right: 1.25rem;
  width: 100%;
}
.modal-dialog .name {
  padding-left: 20px;
}
.modal-dialog .modifier-stepper {
  width: 35%;
  display: flex;
  margin-right: 5%;
  align-items: center;
}
.modifier-stepper .modifier-btn {
  height: '100%' !important;
}
.modal-dialog .price {
  font-weight: 200;
}
.modifier-stepper .number {
  font-size: 1.1rem;
  margin: 0 0.4rem;
}
.modifier-stepper .minus, .modifier-stepper .plus {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  font-weight: bold;
  border-width: 2px;
}
.modifier-name,.modifier-note, .modifier-price {
  font-size: 1rem;
}
.modifier-warning{
  font-size: 0.9rem;
}
.modal-dialog .form-group:first-child {
  margin-top: 10px;
}
.modal-footer{
  display: flex;
  justify-content: space-between;
}

.second-layer{
  margin-bottom: 1.5rem;
}
@media(min-width: 1400px) {
  .item-title, .item-price {
    font-size: 1.75rem !important ;
  } 
  .modifier-btn {
    font-size:  1.125rem !important;
  }
  .modifier-stepper .number,
  .modifier-name,
  .modifier-note, 
  .modifier-price,
  .modifier-warning,
  .item-message {
    font-size: 1.2rem !important; 
  }
  body input[type="checkbox"], body input[type="radio"] {
    margin-top: 0.3rem !important;
    
  }
  .modifier-stepper .minus, .modifier-stepper .plus {
    font-size: 1.5rem !important;
    height: 1.7rem !important;
    width: 1.7rem !important;
  }
  .item-message{
    height: 7rem !important;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    margin-top: 7rem !important;
  }
  .modal-whole {
    padding: 0.5rem !important;
}
}
@media(max-width: 600px) {
  .item-title, .item-price {
    font-size: 1.6rem !important ;
  } 
}
@media (max-width: 576px) {
  .modal-dialog {
    margin: 10vh 5vw 10vh !important;
  }
  .modifier-stepper .number {
    margin: 0 !important;
  }
  .modal-header .item-description,
  .item-message {
    font-size: 1rem;
    margin: 0.5rem !important;
  }
  .item-title, .item-price {
    margin: 0.5rem !important;
  } 
  .modifier-name, .modifier-note,
  .modifier-price, .item-message-limit {
    font-size: 0.9rem !important;
  }
}
@media(max-width: 500px) {
  .item-title, .item-price {
    font-size: 1.6rem !important ;
  } 
  .modal-dialog {
    margin: 8vh 5vw 10vh !important;
  }
  .modal-whole {
    padding: 0 !important;
  }
}
@media(max-width: 400px) {
  .modal-dialog {
    margin: 7vh 5vw 10vh !important;
  }
  .item-title, .item-price {
    font-size: 1.4rem !important ;
  } 
  .item-description, .item-message {
    font-size: 0.9rem !important;
  }
  .modifier-name, .modifier-note, 
  .modifier-price, .item-message-limit {
    font-size: 0.9rem !important;
  }
  .modal-dialog .form-check-label {
    padding-right: 1rem;
  }
}
@media(max-width: 300px) {
  .modal-whole {
    padding: 0 !important;
  }
  .item-title, .item-price {
    font-size: 1.2rem !important ;
  } 
  .item-description, .item-message {
    font-size: 0.8rem !important;
    line-height: initial;
  }
  .modifier-name, .modifier-note, 
  .modifier-price,.item-message-limit {
    font-size: 0.8rem !important;
  }
  .modal-dialog .form-check-label {
    padding-right: 0.5rem;
  }
  .namePrice, .itemDescription {
    margin: 0 !important;
  }
  .item-card .item .info {
    align-content: space-between;
    padding: 6px 10px !important;
  }
  .modifier-stepper .minus, .modifier-stepper .plus {
    height: 1.5rem !important;
    width: 1.5rem !important;
    font-size: 1.2rem !important;
  }
  .modifier-btn {
    font-size: 0.6rem !important;
    padding: 0.5rem !important;
  }
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height: 1500px) {
  .modal-dialog,
  .modal-content {
    max-width: 75vw;
    width: 75vw !important;
  }
  .modal-whole {
    padding: 4vw 5vw !important;
  }
  .item-title, .item-price {
    font-size: 2rem !important ;
  } 
  .modifier-btn,
  .modal-header .item-description {
    font-size:  1.3rem !important;
  }
  .modifier-stepper .number,
  .modifier-name,
  .modifier-note, 
  .modifier-price,
  .modifier-warning,
  .item-message {
    font-size: 1.3rem !important; 
    letter-spacing: 1px;
  }
  body input[type="checkbox"], body input[type="radio"] {
    margin-top: 0.3rem !important;

  }
  .modifier-stepper .minus, .modifier-stepper .plus {
    font-size: 1.5rem !important;
    height: 1.7rem !important;
    width: 1.7rem !important;
  }
  .item-message{
    height: 7rem !important;
  }
  .second-layer {
    margin-bottom: 1rem;
  }
  .modal-dialog .form-check-label {
    margin-left: 0.5rem;
  }
}