.user-preferences {
  display: flex;
  margin-top: 7rem;
  display: flex;
  justify-content: space-around;
}

.user-preferences .sidenav {
  width: 25%;
}

.user-preferences>.content {
  padding: 0 20px;
}
.user-preferences .payment-info,
.user-preferences .personal,
.user-preferences .address {
  margin-bottom: 2vw;
}

.user-preferences h4{
  font-size: 1.5rem !important;
}
.user-preferences .personal-detail,
.user-preferences .contact-detail,
.user-preferences .payment-detail {
  font-size: 1rem !important;
}
.user-preferences .btn {
  font-size: 0.9rem !important;
}
.personal, .contact {
  width: 100%;
}
.user-preferences button {
  margin: 10px 0;
}

.user-preferences .storeBadge {
  width: 150px;
}

.user-preferences .storeBadge img {
  width: 100%;
}

.user-preferences .hr {
  margin-bottom: 20px;
}

.user-preferences .locale {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-preferences .locale .names {
  width: 48%;
  min-width: 250px;
}

.user-preferences .locale .state-select {
  height: 58% !important;
}

.personal-info-button-block, .contact-info-button-block {
  display: flex;
  flex-wrap: wrap;
}


.personal-info-button, .contact-info-button {
  padding-right: 10px;
}
.btn-secondary:hover{
  border-color: transparent;
}

@media(min-width: 701px) {
  .user-preferences {
    margin-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .user-preferences .payment-info,
  .user-preferences .personal,
  .user-preferences .address {
    margin-bottom: 4vw !important;
  }
  .user-preferences h4{
    font-size: 1.5rem !important;
  }
  .user-preferences .personal-detail,
  .user-preferences .contact-detail,
  .user-preferences .payment-detail {
    font-size: 1rem !important;
  }
  .user-preferences .btn {
    font-size: 0.9rem !important;
  }
}
@media(min-width: 1400px) {
  .user-preferences {
    margin-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .user-preferences .payment-info,
  .user-preferences .personal,
  .user-preferences .address {
    margin-bottom: 4vw !important;
  }
  .user-preferences h4{
    font-size: 1.6rem !important;
  }
  .user-preferences .personal-detail,
  .user-preferences .contact-detail,
  .user-preferences .payment-detail {
    font-size: 1.1rem !important;
  }
  .user-preferences .btn {
    font-size: 1rem !important;
  }
}
@media(max-width: 700px) {
  .user-preferences {
    margin-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .user-preferences .payment-info,
  .user-preferences .personal,
  .user-preferences .address {
    margin-bottom: 4vw !important;
  }
  .user-preferences h4{
    font-size: 1.3rem !important;
  }
  .user-preferences .personal-detail,
  .user-preferences .contact-detail,
  .user-preferences .payment-detail {
    font-size: 0.9rem !important;
  }
  .user-preferences .btn {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 576px) {
  .user-preferences {
    margin-top: 5rem;
    flex-direction: column;
  }

  .user-preferences .sidenav {
    display: none;
  }

  .user-preferences .payment-info {
    margin-top: 32px;
  }

  .user-preferences .address {
    margin-top: 32px;
  }

  .user-preferences .download-app {
    margin-top: 32px;
  }
}
