.footer {
  padding: 2vh 3vw 2vh 3vw;
  text-align: center;
  height: 5vh;
  position: fixed;
  left:0;
  bottom:0;
  right:0;
  width: 100%;
}

.footer .col-md-4 {
  padding: 0 5%;
}

.footer .textAccent-strong {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding: '30px 0 20px';
}

.footer .required-container {
  display: flex;
  justify-content: space-between;
}
.footer .link-paging, 
.footer .required-container {
  font-size: 1rem !important;
}

@media(min-width: 1400px) {
  .footer .link-paging, 
  .footer .required-container {
    font-size: 1.1rem !important;
  }
}

@media(min-width: 1600px) {
  .footer .link-paging, 
  .footer .required-container {
    font-size: 1.2rem !important;
  }
}
@media (max-width: 999px) {
  .footer .link-paging, 
  .footer .required-container {
    font-size: 0.9rem !important;
  }
}
@media (max-width: 576px) {
  .footer .required-container {
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .footer .link-paging, 
  .footer .required-container {
  font-size: 0.8rem !important;
  }
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height: 1500px) {

  .footer .link-paging,
  .footer .required-container {
    font-size: 1.3rem !important;
  }
}