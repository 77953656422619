.app-nav {
  display: flex;
  align-items: center;
  padding: 0px 3vw;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 3vw;
}
.app-nav .dropdown {
  border-radius: 5px;
}
.app-nav .dropdown-toggle {
  padding: 0 1vw;
  background-color: transparent !important;
}
.app-nav .show>.dropdown-menu{
  width: fit-content !important;
  transform: translate3d(0px, 2vw ,0 ) !important;
}

.app-nav .caption, .app-nav .login-option{
  font-size: 1rem;
}

.app-nav .login-option .btn-secondary,
.app-nav .login-option .btn-secondary:focus {
  box-shadow: unset;
}

.app-nav .cart-icon .a,
.app-nav .shopping-cart-icon{
  font-size: 1.2rem;
}
.caption-circle{
  border-radius: 50%;
  height: 2vw;
  display: flex;
  width: 2vw;
  justify-content: center;
  align-items: center;
  margin: 0 2vw;
}
.app-nav .show>.dropdown-item,
.app-nav a {
  font-size: 1.2rem ;
}
@media (min-width: 2000px) {
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 1.5rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.6rem !important;
  }
}
@media (min-width: 1800px) {
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 1.4rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.5rem !important;
  }
}
@media (min-width: 1600px) {
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 1.3rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.4rem !important;
  }
}
@media (min-width: 1400px) {
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 1.2rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.3rem !important;
  }
}
@media (max-width: 999px) {
  .app-nav{
    padding: 0;
  }
  .caption-circle{
    height: 2.3vw !important;
    width: 2.3vw !important;
  }
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 0.9rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.1rem !important;
  }
  .cart-icon {
    margin-right: 1.5vw !important;
  }

  .store-homepage {
    margin-left: 1.5vw !important;
  }
}
@media (max-width: 800px) {
  .app-nav {
    height: 4vw !important;
  }
  .app-nav .show>.dropdown-menu{
    width: 30vw !important;
    transform: translate3d(0px, 3.3vw ,0 ) !important;
  }
  .caption-circle{
    height: 3vw !important;
    width: 3vw !important;
  }
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 0.9rem !important;
  }

  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1rem !important;
  }
}
@media ( max-width: 699px ) {
  
  /* .app-nav .show>.dropdown-menu{
    min-width: 30vw !important;
    width: min-content !important;
    
    /* transform: translate3d(0px,4vw ,0 ) !important; 
  } */
}
@media(max-width: 700px) {
  .app-nav {
    height: 5.8vw !important;
  }
  .caption-circle{
    height: 5vw !important;
    width: 5vw !important;
  }
  .app-nav .show>.dropdown-menu{
    min-width: min-content !important;
    transform: translate3d(0px, 4vw ,0 ) !important;
  }
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 0.8rem !important;
  }
  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1rem !important;
  }
  .app-nav .caption{
    margin: 0 1vw !important;
  }
}
@media(max-width: 570px) {
  .app-nav{
    height: 7vw !important;
    min-width: min-content !important;
  }
  .app-nav .show>.dropdown-menu{
    min-width: min-content !important;
    transform: translate3d(0, 4.5vw , 0) !important;
  }
  .caption-circle{
    height: 5vw !important;
    width: 5vw !important;
  }
}
@media ( max-width: 400px ) {
  .app-nav {
    height: 7vw !important;
  }
  .app-nav .show>.dropdown-menu{
    min-width: min-content !important;
    transform: translate3d(0, 6.3vw ,0) !important;
  }

  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 0.7rem !important;
  }
}
@media(max-width: 320px) {
  .app-nav{
    height: 7vw !important;
  }
  .app-nav .show>.dropdown-menu{
    min-width: min-content !important;
    transform: translate3d(0, 7vw ,0) !important;
  }
  .caption-circle{
    height: 5vw !important;
    width: 5vw !important;
  }
  .app-nav .login-option, 
  .app-nav .caption,
  .app-nav .show>.dropdown-item,
  .app-nav a,
  .app-nav .store-homepage {
    font-size: 0.7rem !important;
  }
  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 1.1rem !important;
  }
}
.app-nav .logo {
  margin: 0;
  display: flex;
  align-items: center;
}

.app-nav .logo {
  padding-right: 10px;
}

.app-nav .logo img {
  margin-left: 2vw;
  max-width: 150px;
}

.app-nav .logo h1,
.app-nav .logo h2,
.app-nav .logo h2 {
  padding-left: 20px;
}

.app-nav .login {
  margin-left: auto;
}

.app-nav a {
  text-decoration: none;
}
.app-nav .cart-icon {
  align-items: center !important;
  display: flex;
  margin-left: 1vw;
}

.app-nav .cart-icon a {
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height: 1500px) {
  .app-nav {
    height: 7vw !important;
    padding: 1vw 0;
  }
  .caption-circle{
    height: 4vw !important;
    width: 4vw !important;
  }
  .app-nav .show>.dropdown-menu{
    min-width: min-content !important;
    transform: translate3d(0px, 4vw ,0 ) !important;
  }
  /* .app-nav .login-option {
    display: none;
  } */
  .app-nav .caption,
  .app-nav a{
    font-size: 1.5rem !important;
  }
  .app-nav .caption {
    font-weight: bold;
  }
  .app-nav .cart-icon .a,
  .app-nav .shopping-cart-icon{
    font-size: 2rem !important;
  }
  .app-nav .caption{
    margin: 0 1vw !important;
  }
}

.store-homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.store-homepage:hover {
  margin: 0.05vw;
}

.store-homepage-icon {
  margin-right: 0.4vw;
}