.doc-body {
  padding: 8vh 5vw 6vh 5vw;
  width: 100vw;
}

.bold {
  font-weight: bold;
}

.paragraph {
  margin-top: .5vh;
  margin-bottom: .5vh;
  word-wrap: break-word;
}

.list-item {
  margin-left: 2vw;
}

.bullet-point {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 2vw;
}
