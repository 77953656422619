.order-card {
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
}

.order-card:hover {
  border-width: 1px;
  border-style: solid;
}
.hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.restaurant-img {
  width: 200px;
  height: 150px;
  margin: 10px;
}

@media(max-width: 350px) {
  .history-order-title {
    font-size: 1.3rem !important;
  }
  .history-order-content{
    font-size: 0.8rem !important;
  }
  .history-order-fee {
    font-size: 1rem !important;
  }
}
@media(max-width: 300px) {
  .history-order-title {
    font-size: 1.2rem !important;
  }
}