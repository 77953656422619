.menu-item-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: transparent;
  margin:  0vw 0 1.5vw;
}
.menu-item-card:hover {
  cursor: pointer;
}
.menu-item-card .name {
  font-size: 1.1rem;
  flex: 2;
  height: 100%;
}
.menu-item-card .qty {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  flex: 1;
}
.menu-item-card .qty .minus, .menu-item-card .qty .plus {
  height: 1.5vw;
  width: 1.5vw;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  font-weight: bold;
  border-width: 2px;
}

.menu-item-card .qty .number {
  text-align: center;
  vertical-align: middle;
  width: 3vw;
  font-size: 1.1rem;
}
.menu-item-card .price {
  font-size: 1.1rem;
  flex: 1;
}
.menu-item-card .delete-button {
  padding: 10px;
  float: right;
  height: 40px;
}
.menu-item-card .modal-content{
  border-radius: 10px;
  border-color: transparent !important;
  background-color: transparent !important;
}
.menu-item-card .modal-whole {
  border-radius: 10px;
  padding: 1.5rem;
}
@media(min-width: 1300px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 1rem !important;
  }

}
@media(min-width: 1500px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 1.2rem !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
}
@media(min-width: 1800px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 1.3rem !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
}
@media(max-width: 1299px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 0.9rem !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
}
@media(max-width: 1100px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 0.8rem !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.8vw !important;
    width: 1.8vw !important;
  }
}
@media(max-width: 1000px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 0.7rem !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.7vw !important;
    width: 1.7vw !important;
  }
}
@media(max-width: 900px) {
  .menu-item-card .qty .number,
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus,
  .menu-item-card .qty,
  .menu-item-card .name,
  .menu-item-card .price{
    font-size: 0.7rem !important;
  }
  .menu-item-card .qty .number{
    width: 2vw !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    border-width: 1.5px !important;
  }
  .menu-item-card .qty .minus,
  .menu-item-card .qty .plus {
    height: 1.6vw !important;
    width: 1.6vw !important;
  }
}