.menu-summary {
  border-radius: 0 0 15px 15px;
  border-color: transparent;
  box-shadow: 0px 3px 6px #00000029;
  width: 27vw;
  min-height: 21vw;
  padding-bottom: 0.5vw;
}

.menu-summary .form-check-inline {
  display: flex !important;
  align-items: center !important;
}

.menu-summary .radio-option {
  height: 3vw;
  display: flex;
  justify-content: space-between;
  padding: 0 1vw;
  flex-direction: row;
  font-size: 1rem;
}

.menu-summary .radio-option .form-check-label {
  margin-top: 0.3rem;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
}

.menu-summary .cart-item-card {
  margin: 0;
}

.menu-summary .text-danger {
  font-size: 1.1rem !important;
  margin: 2vw !important;
}

.menu-summary .menu-items-list {
  margin: 2vw;
  max-height: 15vw;
  overflow-y: scroll;
  padding: 0 1vw 0 0;
}

.menu-summary .suboption-fee {
  margin: 0.5vw 4vw;
  font-size: 1.1rem;
}

.menu-summary .total-fee {
  margin: 0.5vw 4vw;
  font-size: 1.2rem;
}

.menu-summary .checkout-button-section {
  margin: 2vw 6vw;
  display: flex;
  justify-content: center;
}

.menu-summary .checkout-button {
  border-radius: 10px;
}

.menu-summary .checkout-button .btn {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}

.menu-summary .table-info {
  height: 3vw;
  display: flex;
  justify-content: space-between;
  padding: 0 2vw;
  flex-direction: row;
  align-items: center;
  border: none;
}

.menu-summary .table-info-text {
  font-size: 1.2rem;
  letter-spacing: 1px;
}

@media(min-width: 2000px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 1.1rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1.3rem !important;
  }

  .menu-summary .menu-items-list {
    max-height: 15.5vw;
  }
}

@media(min-width: 1800px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 1rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1.2rem !important;
  }
}

@media(min-width: 1600px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 1rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1.2rem !important;
  }
}

@media(min-width: 1300px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 1rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1.2rem !important;
  }
  /* .menu-summary .menu-items-list {
    max-height: 6vw;
  } */
}

@media(max-width: 1299px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 1rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1rem !important;
  }
}

@media(max-width: 1100px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 0.9rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 1rem !important;
  }

  .menu-summary .menu-items-list {
    max-height: 16.6vw;
  }
}

@media(max-width: 999px) {

  .menu-summary .table-info,
  .menu-summary .radio-option {
    height: auto !important;
    min-height: 4vw !important;
    padding: 0 2vw;
    flex-wrap: wrap;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 0.9rem !important;
  }
}

@media(max-width: 900px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 0.8rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 0.9rem !important;
  }

  .menu-summary .menu-items-list {
    max-height: 17.5vw;
  }
}

@media(max-width: 750px) {
  .menu-summary .menu-items-list {
    max-height: 17vw;
  }
}

@media(max-width: 700px) {

  .menu-summary .suboption-fee,
  .menu-summary .text-danger {
    font-size: 0.8rem !important;
  }

  .menu-summary .radio-option .form-check-label,
  .menu-summary .checkout-button .btn,
  .menu-summary .total-fee,
  .menu-summary .table-info-text {
    font-size: 0.9rem !important;
  }
}