.password-reset {
  margin-top: 5px;
  /* color: #337ab7; */
}

.password-reset:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sign-up-button-space {
  height: 30px;
}

.login-page,
.register-page {
  margin-top: 8rem;
  padding-left: 5vw;
  padding-right: 5vw;
}
.card-group {
  margin-top: 8rem !important;
  width: 90vw !important;
}
.card .password-reset,
.card .text-muted,
.card .btn,
.card .form-control {
  font-size: 1rem;
}
.card h2 {
  font-size: 3.5rem;
}

button a {
  color: white !important;
}

.register-link {
  display: none;
}

@media (min-width: 700px) {
  .card-group {
    margin-top: 8rem !important;
    width: 80vw !important;
  }
  .card .password-reset,
  .card .text-muted,
  .card .btn,
  .card .form-control {
    font-size: 1rem !important;
  }
  .card h2 {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 1000px) {
  .card-group {
    margin-top: 8rem !important;
    width: 70vw !important;
  }
  .card .password-reset,
  .card .text-muted,
  .card .btn,
  .card .form-control {
    font-size: 1rem !important;
  }
  .card h2 {
    font-size: 3.5rem !important;
  }
}
@media (min-width: 1400px) {
  .card-group {
    margin-top: 8rem !important;
    width: 60vw !important;
  }
  .card .password-reset,
  .card .text-muted,
  .card .btn,
  .card .form-control {
    font-size: 1.1rem !important;
  }
  .card h2 {
    font-size: 3.6rem !important;
  }
}
@media (min-width: 1800px) {
  .card-group {
    margin-top: 8rem !important;
    width: 50vw !important;
  }
  .card .password-reset,
  .card .text-muted,
  .card .btn,
  .card .form-control {
    font-size: 1.2rem !important;
  }
  .card h2 {
    font-size: 3.7rem !important;
  }
}
@media (max-width: 699px) {
  .login-page,
  .register-page {
    margin-top: 8rem !important;
  }
  .card-group {
    margin-top: 8rem !important;
    width: 90vw !important;
  }
  .sign-up-card {
    display: none;
  }

  .register-link {
    display: block !important;
  }
}
@media (max-width: 570px) {
  .card-group {
    margin-top: 4rem !important;
    width: 90vw !important;
  }
  .card .password-reset,
  .card .text-muted,
  .card .btn,
  .card .form-control,
  .card .register-link a {
    font-size: 0.8rem !important;
  }
  .card h2 {
    font-size: 3rem !important;
  }
}
