.default-message {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
}
.default-title {
  padding: 0 2rem;
  white-space: pre-wrap;
  text-align: center;
}
.sideNav {
  width: 250px;
  border-radius: 6px;
  height: inherit;
  position: fixed;
}

.order-list {
  width: stretch;
  margin-left: 300px;
  max-width: stretch;
  margin-right: 30px;
}

.month-block {
  display: flex;
  flex-direction: column;
}

.month-title {
  align-self: center;
  font-size: 24px;
}


.home-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media(max-width: 999px) {
  .sideNav {
    width: 200px;
  }
  .order-list {
    margin-left: 250px !important;
    margin-right: 1rem !important;
  }
}
@media(max-width: 699px) {
  .sideNav {
    width: 180px;
  }
  .order-list {
    margin-left: 210px !important;
    margin-right: 1rem !important;
  }
}
@media (max-width: 576px) {
  .sideNav {
    display: none;
  }

  .order-list {
    margin: auto !important;
    padding: 0 20px !important;
  }
}
