.swipe-card-modal {
  border-radius: 10px;
  border-color: transparent;
}

.swipe-card-modal-title {
  font-size: 1.5rem;
}

.swipe-card-modal-body {
  padding: 2rem 3rem;
}

.swipe-card-group,
.swipe-card-modal-footer {
  display: flex;
  justify-content: center;
}

.swipe-card-btn-group {
  display: none;
  justify-content: center;
}

.swipe-card-btn {
  height: 6rem;
  width: 9rem;
  font-size: 1.2rem;
  padding: 0.5rem;
  white-space: pre-wrap;
  letter-spacing: 0.5px;
  box-shadow: none;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipe-card-modal-header,
.swipe-card-modal-footer {
  padding: 1rem;
}

.payment-action-btn {
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  box-shadow: none;
}

.swipe-card-btn:focus,
.swipe-card-btn:hover,
.payment-action-btn:focus,
.payment-action-btn:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

@media(max-width: 700px) {
  .swipe-card-modal-title {
    font-size: 1.35rem;
  }

  .swipe-card-modal-header,
  .swipe-card-modal-footer {
    padding: 1rem !important;
  }

  .swipe-card-modal-body {
    padding: 1rem 3rem !important;
  }

  .swipe-card-btn {
    height: 5rem;
    width: 8rem;
    font-size: 1.1rem;
  }
}

@media(max-width: 500px) {
  .swipe-card-modal-title {
    font-size: 1.25rem;
  }

  .swipe-card-modal-header,
  .swipe-card-modal-footer {
    padding: 1rem 1.5rem !important;
  }

  .swipe-card-modal-body {
    padding: 1rem 2rem !important;
  }
}

@media(max-width: 400px) {
  .swipe-card-modal-title {
    font-size: 1.25rem;
  }

  .swipe-card-modal-header,
  .swipe-card-modal-footer {
    padding: 1rem !important;
  }

  .swipe-card-modal-body {
    padding: 1rem 1rem !important;
  }

  .swipe-card-btn {
    height: 4rem;
    width: 7rem;
    font-size: 1rem;
  }
}