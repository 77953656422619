@font-face {
  font-family: "OpenSans-Bold";
  src: url("./fonts/OpenSans/OpenSans-Bold.eot");
  src: local("./fonts/OpenSans/OpenSans-Bold Regular"),
    local("./fonts/OpenSans/OpenSans-Bold"),
    url("./fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./fonts/OpenSans/OpenSans-SemiBold.eot");
  src: local("./fonts/OpenSans/OpenSans-SemiBold Regular"),
    local("./fonts/OpenSans/OpenSans-SemiBold"),
    url("./fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("./fonts/OpenSans/OpenSans-Regular.eot");
  src: local("./fonts/OpenSans/OpenSans-Regular Regular"),
    local("./fonts/OpenSans/OpenSans-Regular"),
    url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PetitaBold";
  src: url("./fonts/Petita/PetitaBold.eot");
  src: local("./fonts/Petita/PetitaBold Regular"),
    local("./fonts/Petita/PetitaBold"),
    url("./fonts/Petita/PetitaBold.ttf") format("truetype"),
    url("./fonts/Petita/PetitaBold.svg#font") format("svg");
}
@font-face {
  font-family: "PetitaMedium";
  src: url("./fonts/Petita/PetitaMedium.eot");
  src: local("./fonts/Petita/PetitaMedium Regular"),
    local("./fonts/Petita/PetitaMedium"),
    url("./fonts/Petita/PetitaMedium.ttf") format("truetype"),
    url("./fonts/Petita/PetitaMedium.svg#font") format("svg");
}
@font-face {
  font-family: "PetitaLight";
  src: url("./fonts/Petita/PetitaLight.eot");
  src: local("./fonts/Petita/PetitaLight Regular"),
    local("./fonts/Petita/PetitaLight"),
    url("./fonts/Petita/PetitaLight.ttf") format("truetype"),
    url("./fonts/Petita/PetitaLight.svg#font") format("svg");
}
@font-face {
  font-family: 'HelveticaNeueBold';
  src: local('Helvetica Neue Bold'), url("./fonts/HelveticaNeue/HelveticaNeue-Bold-02.ttf") format('woff');
  }

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: local('Helvetica Neue Medium'), url("./fonts/HelveticaNeue/HelveticaNeue-Medium-11.ttf") format('woff');
}

@font-face {
  font-family: 'HelveticaNeueRegular';
  src: local('Helvetica Neue Regular'), url("./fonts/HelveticaNeue/HelveticaNeue-01.ttf") format('woff');
}

@font-face {
  font-family: 'HelveticaNeueCondensedBlack';
  src: local('Helvetica Neue CondensedBlack'), url("./fonts/HelveticaNeue/HelveticaNeue-CondensedBlack-10.ttf") format('woff');
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: local('Helvetica Neue Light'), url("./fonts/HelveticaNeue/HelveticaNeue-Light-08.ttf") format('woff');
}

@font-face {
  font-family: 'Impact';
  src: local('Impact'), url("./fonts/impact.ttf") format('woff');
}

@font-face {
  font-family: 'AbrilFatface';
  src: local('AbrilFatface'), url("./fonts/AbrilFatface-Regular.ttf") format('woff');
}

.modifier-within {
  margin-left: 5%;
  font-size: 1.2rem;
  font-style: italic;
}
.modifier-within .form-group:first-child {
  margin-top: 0px !important;
}

.order-history {
  margin-top: 7rem;
}
.order-history .textAccent-strong {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding: "30px 0 20px";
}
.order-history .order-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-history .order-list > div {
  width: 100%;
  padding-top: 40px;
}
.order-history .order-item .card {
  flex-direction: row;
  padding: 10px;
}
.order-history .order-item .card .column2 {
  flex: 1 1 auto;
}
.order-history .order-item .card .column3 {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
}
.order-history .order-item .card .column3 .btn {
  margin: 10px 0;
}
.order-history .order-item .card .column3 .btn-outline-primary {
  /* border-color: #2e6da4; */
  color: #2e6da4;
}
.order-history .order-item .card .column3 .btn-outline-primary:hover {
  /* background-color: #2e6da4; */
  /* color: white; */
}
.order-history .order-item .card .column3 .btn:first-child {
  margin: 0;
}
.order-history .order-item .card .header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 15px;
  flex-wrap: wrap;
}
.order-history .order-item .card .header .card-title {
  flex: 1 0 80%;
}
.order-history .order-item .card .header .description {
  flex: 1 0 100%;
}
.order-history ul {
  padding: 0;
}
.order-history ul li {
  padding: 5px 0;
  border-bottom: 1px solid;
  margin: 7px 0;
}
.order-history ul li:last-child {
  border: none;
}
.order-history .sidenav {
  display: none;
}
@media (max-width: 425px) {
  .order-history .order-list .order-item .card {
    flex-direction: column;
  }
  .order-history .order-list .order-item .card .column3 {
    flex-direction: row;
    justify-content: space-evenly;
    flex: 0 0 40px;
  }
  .order-history .order-list .order-item .card .column3 .btn {
    margin: 0;
    width: 40%;
  }
  .order-history .order-list .order-item img {
    display: none;
  }
}
@media (min-width: 768px) {
  .order-history .sidenav {
    display: block;
  }
  .order-history .order-list {
    align-items: flex-end;
  }
  .order-history .order-list > div {
    width: 27em;
  }
  .order-history .order-list .order-item img {
    display: none;
  }
}
@media (min-width: 1024px) {
  .order-history .order-list > div {
    width: 42em;
  }
  .order-history .order-list .order-item img {
    display: block;
  }
}
@media (min-width: 1440px) {
  .order-history .order-list > div {
    width: 77%;
  }
}

@media (max-width: 576px) {
  .modal-footer {
    padding: 15px 10px 15px 10px;
  }

  .stepper {
    width: 50% !important;
    margin: 0 !important;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  font-family: 'HelveticaNeueRegular';
  font-size: 16px;
  font-weight: 500;
  color: black;
  background-color: #f6f6f6;
  /* padding-bottom: 5vh;
  /* min-height: 100%; */
}
body input[type="checkbox"],
body input[type="radio"] {
  margin: 0.25rem 0 0;
  margin-top: 1px\9;
  line-height: normal;
  width: 17px;
  height: 17px;
}
body h1 {
  font-size: 64px;
  font-family: 'HelveticaNeueBold';
}
body h2 {
  font-size: 48px;
  font-family: 'HelveticaNeueBold';
}
body h3 {
  font-size: 38px;
  font-family: 'HelveticaNeueBold';
}
body h4 {
  font-size: 28px;
  font-family: 'HelveticaNeueBold';
  text-shadow: 0 0.4px 0.5px black;
  letter-spacing: 0.3px;
}
body h5 {
  font-size: 28px;
  font-family: 'HelveticaNeueBold';
}
body .textAccent-strong {
  font-size: 21px;
  font-family: 'HelveticaNeueBold';
}
body .textAccent {
  font-size: 21px;
  font-family: 'HelveticaNeueBold';
}
body p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'HelveticaNeueRegular';
}
body .text,
body button {
  border-radius: 6px;
  font-size: 16px;
  font-family: 'HelveticaNeueRegular';
}
body .p-muted {
  font-size: 16px;
  font-family: 'HelveticaNeueRegular';
  color: rgba(0, 0, 0, 0.45);
}
body .p-mini {
  font-size: 12px;
  font-family: 'HelveticaNeueRegular';
}
body a {
  font-size: 16px;
  font-family: 'HelveticaNeueRegular';
  /* color: #337ab7; */
}
body li {
  list-style-type: none;
}
body .view {
  padding: 0 1.5em;
}

.modal-footer > :not(:first-child) {
  margin: 0 0.35rem;
}

.dropdown-toggle {
  border: none;
}

.no-border {
  border: none;
}

.noshow {
  display: none !important;
}

.app {
  display: block;
}
.app .hr {
  width: 100%;
  background-color: black;
  padding: 0.6px;
}
.app .content {
  width: 100%;
}

.app-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 90vh;
  height: 100%;
}
.app-wrapper .main-content {
  flex: 1;
  background-color: #f6f6f6;
}

.fade-enter {
  right: 0px;
}

.fade-enter-active {
  right: 0px;
  transition: all 0.5s ease-in;
}

.fade-leave {
  right: 0px;
  opacity: 1;
}

.fade-leave-active {
  right: 1500px;
  opacity: 0.1;
  transition: all 1s ease-out;
}

.restaurant-hero {
  background-image: url("./img/home-background.jpg");
  background-size: cover;
  background-position: center;
  height: 25em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.restaurant-hero .form-group {
  width: 50%;
  margin: 0;
}
.restaurant-hero form > div {
  width: 100%;
  display: inline-block;
}
.restaurant-hero button {
  flex: 1 0 1;
  background-color: white;
  padding: 0 20px;
  font-size: 17px;
  border-radius: 0;
  border: 0;
  background-color: #d8d8d8;
}
@media (max-width: 576px) {
  .restaurant-hero .form-group {
    width: 90%;
  }
}

@media (max-width: 320px) {
  .modal-footer {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin-top: 5%;
  }
}

.CreditCard {
  max-width: 400px;
}
.CreditCard .credit-card-list {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}
.CreditCard .credit-card-list li {
  float: left;
  color: #aaa;
  font-size: 26px;
}
.CreditCard .credit-card-list li:not(:last-child) {
  margin-right: 10px;
}
.CreditCard .credit-card-list .active {
  color: #666;
}
.CreditCard .alert {
  margin-top: 20px;
}
.CreditCard .alert h5 {
  margin-top: 0px;
}
.CreditCard .alert .exp-cvc {
  margin-bottom: 5px;
}
.CreditCard .alert .exp-cvc span:first-child {
  margin-right: 15px;
}
::-webkit-scrollbar {
  display: none;
}
.modal-body {
  font-size: x-large;
}
/*kiosk adjustment*/
@media(max-width:1500px) and (min-height: 1500px) {
  body {
    padding-bottom: 5vh;
    height: 95vh;
  }
}