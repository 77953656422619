.cart-item-card {
  display: flex;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  width: 62vw;
  min-height: 9.7vw;
  margin-bottom: 1.7vw !important;
  border-radius: 10px;
}


.cart-item-card .info {
  display: flex;
  flex-direction: row;
  margin: 1.5vw 2.6vw;
}

.cart-item-card .info .item-name-price {
  display: flex;
  flex-direction: column;
  flex: 10;
  justify-content: center;
}

.cart-item-card .info .name {
  font-weight: bold;
  font-size: 1.5rem;
  flex: 2;
  display: flex;
  align-items: center;
}

.cart-item-card .info .price {
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.5rem;
}

.cart-item-card .info .item-modifier {
  display: flex;
  flex-direction: column;
  flex: 10;
  justify-content: center;
}

.cart-item-card .info .modifier,
.cart-item-card .info .second-level-modifier {
  font-size: 1.2rem;
  font-weight: lighter;
}

.cart-item-card .info .second-level-modifier,
.second-level-modifier .third-level-modifier,
.third-level-modifier .forth-level-modifier{
  margin-left: 1vw;
}

.cart-item-card .info .item-qty {
  flex: 7;

}

.cart-item-card .info .item-description {
  display: flex;
  flex: 15;
  white-space: pre-line;
  word-break: break-all;
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  padding: 0.5vw;
  align-items: flex-start !important;
}

.cart-item-card .info .item-qty,
.cart-item-card .info .item-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-item-card .info .description {
  font-size: 1.2rem;
  padding: 0.5vw;
  /* flex: 2; */
}

.cart-item-card .qty {
  /* flex: 1; */
  display: flex;
  align-items: center;
  /* justify-content:  flex-end; */
}

.cart-item-card .qty .minus,
.cart-item-card .qty .plus {
  height: 1.7vw;
  width: 1.7vw;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  border-width: 2px;
  font-weight: bold;
}

.cart-item-card .qty .minus,
.cart-item-card .qty .plus {
  padding-bottom: 0.25vw !important;
}

.cart-item-card .qty .number {
  text-align: center;
  vertical-align: middle;
  width: 3vw;
  font-size: 1.7rem;
  font-weight: bold;
}

.cart-item-card .info .item-delete {
  flex: 1;
}

.cart-item-card .info .delete-button {
  font-size: 1.7rem;
  flex: 1 1 0%;
  width: 2.5vw;
  display: flex;
  height: 2.5vw;
  padding: 0;
  justify-content: center;
}

.cart-item-card .qty .minus:focus, 
.cart-item-card .qty .plus:focus,
.cart-item-card .info .delete-button:focus,
.cart-item-card .info .trash-icon:focus{
  outline: none;
  box-shadow: none;
}

.cart-item-card .info .trash-icon {
  align-self: center;
  font-size: 1.5rem !important;
}

@media(min-width: 1300px) {

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 1rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button,
  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1.3rem !important;
  }
}

@media(min-width: 1500px) {

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 1.2rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button,
  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1.4rem !important;
  }
}

@media(min-width: 1800px) {

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 1.3rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button,
  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1.5rem !important;
  }
}

@media(max-width: 1299px) {
  .cart-item-card {
    width: 62vw !important;
    min-height: 11vw !important;
  }

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 0.9rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .trash-icon,
  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1.2rem !important;
  }

  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button {
    width: 3vw !important;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 2vw !important;
    width: 2vw !important;
  }
}

@media(max-width: 1000px) {
  .cart-item-card {
    width: 62vw !important;
    min-height: 11vw !important;
  }

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 0.7rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .trash-icon {
    font-size: 1.1rem !important;
  }

  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button {
    width: 3vw !important;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 2.1vw !important;
    width: 2.1vw !important;
    font-size: 1rem !important;
  }
}

@media(max-width: 900px) {
  .cart-item-card {
    min-height: 12vw !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .delete-button,
  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1rem !important;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 2.4vw !important;
    width: 2.4vw !important;
  }
}

@media(max-width: 700px) {
  .cart-item-card {
    width: 100% !important;
    min-height: 21vw !important;
    margin-bottom: 3.5vw !important;
  }

  .cart-item-card .info {
    margin: 2vw 5vw !important;
    flex-direction: column;
  }

  .cart-item-card .info .item-modifier {
    display: flex;
    flex-direction: column;
    flex: 3 !important;
    padding: 0 0 0 2vw;
    justify-content: flex-start !important;
  }

  .cart-item-card .info .item-name-price {
    flex-direction: row !important;
    flex: 2 !important;
  }

  .cart-item-card .info .name {
    flex: 2 !important;
  }

  .cart-item-card .info .price {
    flex: 1 !important;
    align-items: center !important;
    justify-content: flex-end;
  }

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 0.9rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .trash-icon {
    font-size: 1.2rem !important;
  }

  .cart-item-card .qty .number {
    width: 9vw !important;
  }

  .cart-item-card .info .delete-button {
    justify-content: flex-end;
    width: fit-content;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 4vw !important;
    width: 4vw !important;
    font-size: 1.1rem !important;
  }

  .cart-item-card .qty .plus {
    padding-bottom: 0.3vw !important;
  }

  .item-name-price-mod {
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  .item-descript-qty-del {
    flex: 3;
    display: flex;
    flex-direction: row;
  }

  .item-qty-del {
    flex: 1;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .cart-item-card .info .item-description {
    flex: 2;
    margin: 2vw;
    padding: 1vw !important;
    border-radius: 5px !important;
    min-height: 3rem;
  }
}

@media(max-width: 600px) {

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 4.5vw !important;
    width: 4.5vw !important;
  }
}

@media(max-width: 500px) {
  .cart-item-card {
    width: 100% !important;
    min-height: 8rem !important;
    margin-bottom: 4.5vw !important;
  }

  .cart-item-card .info {
    margin: 3vw 5vw !important;
    flex-direction: column;
  }

  .cart-item-card .info .item-modifier {
    display: flex;
    flex-direction: column;
    flex: 3 !important;
    padding: 0 0 0 2vw;
    justify-content: flex-start !important;
  }

  .cart-item-card .info .item-name-price {
    flex-direction: row !important;
    flex: 2 !important;
  }

  .cart-item-card .info .name {
    flex: 2 !important;
  }

  .cart-item-card .info .price {
    flex: 1 !important;
    align-items: center !important;
    justify-content: flex-end;
  }

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 0.8rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .trash-icon,
  .cart-item-card .info .delete-button .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    font-size: 1rem !important;
  }

  .cart-item-card .qty .number {
    width: 9vw !important;
  }
  
  .cart-item-card .info .delete-button {
    width: fit-content !important;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 5vw !important;
    width: 5vw !important;
  }

  .item-name-price-mod {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .item-descript-qty-del {
    flex: 3;
    display: flex;
    flex-direction: row;
  }

  .item-qty-del {
    flex: 1;
    display: flex;
    flex-direction: row !important;
  }

  .cart-item-card .info .item-description {
    flex: 2;
    margin: 2vw;
    /* min-height: 3rem; */
    border-radius: 5px !important;
  }

  .cart-item-card .info .description {
    padding: 1vw !important;
  }
}

@media(max-width: 400px) {

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    height: 5.5vw !important;
    width: 5.5vw !important;
  }
}

/*kiosk adjustment*/
@media(max-width:1500px) and (min-height:1500px) {
  .cart-item-card .info {
    margin: 2vw 4vw !important;
  }

  .cart-item-card .info .modifier,
  .cart-item-card .info .second-level-modifier,
  .cart-item-card .info .description {
    font-size: 1.2rem !important;
  }

  .cart-item-card .info .name,
  .cart-item-card .info .price,
  .cart-item-card .qty .number,
  .cart-item-card .info .trash-icon {
    font-size: 1.4rem !important;
  }

  .cart-item-card .qty .minus,
  .cart-item-card .qty .plus {
    width: 3vw !important;
    height: 3vw !important;
    font-size: 1.5rem !important;
  }

  .cart-item-card .qty .number {
    margin: 0 1vw !important;
  }

  .cart-item-card .info .item-description {
    display: none;
  }
}