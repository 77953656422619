.cc-type-warning {
  color: red;
  margin-top: 20px;
  margin-bottom: 10px;
}
.menu-item-card .modal-whole {
  border-radius: 10px;
  padding: 1.5rem;
}
.order-total {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.margin-horizontal {
  margin-left: 20px;
  margin-right: 20px;
}

.cc-info {
  padding-top: 15px;
}

@media (min-width: 1300px) {
  .modal-title {
    font-size: 1.6rem !important;
  }
  .order-total {
    font-size: 1.5em !important;
  }
  .placeholder-title {
    font-size: 1.1rem !important;
  }
  .input-control {
    font-size: 1.1rem !important;
  }
}
@media(max-width: 300px) {
  .modal-whole{
    padding: 15px 0 !important;
  }
}